@font-face {
  font-family: "arcadepi";
  src: local("arcadepi"),
    url("../fonts/arcadepi.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham Bold";
  src: local("gotham-bold"),
    url("../fonts/gotham-bold.ttf") format("truetype");
}

fieldset {
  border: none !important;
  outline: none !important;
}